import React from 'react';
import './App.css';
import "@fontsource/mulish";
import "@fontsource/mulish/500.css"
import "@fontsource/mulish/700.css"
import "@fontsource/mulish/900.css"
import {
  Route, BrowserRouter as Router, Routes, Navigate, BrowserRouter,
} from 'react-router-dom';
import { Login } from './pages/Login';
import { Signup } from './pages/Signup';
import { Layout } from './layout';
import { Assessment, AssessmentList } from './pages/Assessment';
import { UserSurvey } from './pages/UserSurvey';
import { Company } from './pages/Company';
import { ErrorPage } from './pages/ErrorPage';

function App() {
  
  
  return (
    <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path='/assessments' element={<AssessmentList />} />
            <Route path='/assessment/:id' element={<Assessment />} />
            <Route path='/rdc-admin' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/user_survey/:aId/:sId/:uId' element={<UserSurvey />} />
            <Route path='/company' element={<Company />} />
          </Route>
          <Route path='*' element={<ErrorPage />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
